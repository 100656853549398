<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent="handleSubmit(submitEventCategory)">

      <validation-provider
        #default="validationContext"
        name="Name"
        rules="required"
      >
        <b-form-group
          label="Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="eventCategoryTemp.title"
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
        label="Status"
        label-for="state"
      >
        <b-form-checkbox
          id="state"
          v-model="eventCategoryTemp.status"
        />
      </b-form-group>

      <b-button
        variant="primary"
        type="submit"
      >
        Save
      </b-button>
    </b-form>
  </validation-observer>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'EventCategoryForm',
  props: {
    eventCategory: { type: Object, default: () => {} },
    submit: { type: Function, default: () => null },
  },
  data() {
    return {
      eventCategoryTemp: JSON.parse(JSON.stringify(this.eventCategory)),
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
  methods: {
    submitEventCategory() {
      this.setEventCategory()
      this.submit()
    },
    setEventCategory() {
      this.$emit('update:eventCategory', this.eventCategoryTemp)
    },
  },
}
</script>
<style lang="scss">

</style>
