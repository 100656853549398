<template>
  <b-row
    v-if="!eventCategoryLoaded"
    align-h="center"
  >
    <div
      class="loader"
    />
  </b-row>
  <event-category-form
    v-else
    :submit="editEventCategory"
    :event-category.sync="eventCategory"
  />
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import EventCategoryForm from './EventCategoryForm.vue'

export default {
  name: 'EditEventCategory',
  components: { EventCategoryForm },
  props: {
    eventCategoryId: { type: Number, default: null },
  },
  data() {
    return {
      eventCategory: { status: 'active' },
      eventCategoryLoaded: false,
    }
  },
  computed: {
    statusLookup() {
      return {
        false: 'inactive',
        true: 'active',
      }
    },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  created() {
    this.getEventCategory()
  },
  methods: {
    getEventCategory() {
      this.$activities.get(`/internalops/event/types/${this.eventCategoryId}`).then(res => {
        this.eventCategory = res.data.data
        this.eventCategoryLoaded = true
      })
    },
    editEventCategory() {
      this.$activities.put(`/internalops/event/types/${this.eventCategoryId}`, {
        ...this.eventCategory,
        status: this.statusLookup[this.eventCategory.status],
      }).then(() => {
        this.successfulOperationAlert('Event category is updated successfully')
        this.$bvModal.hide('edit-modal')
        this.refreshTable()
      })
    },
    refreshTable() {
      this.$emit('refresh')
    },
  },
}
</script>
<style lang="scss">

</style>
