<template>
  <div>
    <div
      v-if="$can('store','global-EventTypeController')"
      class="text-right"
    >
      <b-button
        v-b-modal.add-modal
        variant="primary"
        class="mb-1"
      >
        <span class="text-nowrap">Add Category</span>
      </b-button>
    </div>

    <b-card header="Search">
      <b-row>
        <b-col>
          <search
            placeholder="Name"
            :search-query.sync="searchQuery"
            @refresh="refreshTable"
          />
        </b-col>

        <b-col
          md="4"
          class="mt-1 mt-md-0"
        >
          <status-filter
            :status.sync="status"
          />
        </b-col>
        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-row>
    </b-card>

    <event-categories-table
      ref="parentTable"
      v-bind="{
        getEventCategories,
        pagination
      }"
      :event-category-id.sync="selectedEventCategoryId"
    />

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

    <div class="d-flex m-2">
      <!-- Add  -->
      <b-modal
        id="add-modal"
        centered
        title="Add Event Type"
        hide-footer
      >
        <add-event-category
          @refresh="refreshTable"
        />
      </b-modal>

      <!-- Edit -->
      <b-modal
        id="edit-modal"
        centered
        title="Edit Event Type"
        hide-footer
      >
        <edit-event-category
          :event-category-id="selectedEventCategoryId"
          @refresh="refreshTable"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import StatusFilter from '@/common/components/common/FormInputs/StatusFilter.vue'
import AddEventCategory from '@/common/components/GeneralSettings/event-categories/AddEventCategory.vue'
import EventCategoriesTable from '@/common/components/GeneralSettings/event-categories/EventCategoriesTable.vue'
import EditEventCategory from '@/common/components/GeneralSettings/event-categories/EditEventCategory.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'EventCategories',
  components: {
    Pagination,
    Search,
    SearchButton,
    StatusFilter,
    EventCategoriesTable,
    AddEventCategory,
    EditEventCategory,
  },
  data() {
    return {
      searchQuery: null,
      status: null,
      selectedEventCategoryId: null,
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    getEventCategories() {
      const promise = this.$activities.get('/internalops/event/types', {
        params: {
          key: this.searchQuery,
          page: this.pagination.currentPage,
          status: this.status,
          order: 'desc',
        },
      })

      return promise.then(res => {
        const items = res.data.data

        this.pagination.totalRows = res.data.pagination.total
        return items || []
      }).catch(() => [])
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
