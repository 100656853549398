<template>
  <b-card>
    <b-table
      ref="table"
      :items="getEventCategories"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      responsive
      empty-text="No event categories found"
      show-empty
    >
      <!-- Column: Actions -->
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <toggle-status
            :item="item"
            :activate-url="activateUrl"
            :deactivate-url="deactivateUrl"
          />
          <b-dropdown-item
            v-if="$can('update','global-EventTypeController')"
            v-b-modal.edit-modal
            variant="outline-primary"
            @click="setSelectedEventCategoryId(item.id)"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import ToggleStatus from '../common/ToggleStatus.vue'

export default {
  name: 'EventCategoriesTable',
  components: { ToggleStatus },
  props: {
    getEventCategories: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      tableColumns: [
        { key: 'id', label: '#' },
        { key: 'title', label: 'Name' },
        { key: 'status', formatter: v => (v ? 'Activated' : 'Deactivated') },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/event/category/`,
      activateUrl: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/event/activate/type/`,
      deactivateUrl: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/event/deactivate/type/`,
    }
  },
  methods: {
    setSelectedEventCategoryId(id) {
      this.$emit('update:eventCategoryId', id)
    },
  },
}
</script>

<style>

</style>
