<template>
  <event-category-form
    :submit="addEventCategory"
    :event-category.sync="eventCategory"
  />
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import EventCategoryForm from './EventCategoryForm.vue'

export default {
  name: 'AddEventCategory',
  components: { EventCategoryForm },
  data() {
    return {
      eventCategory: { status: 'active' },
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  methods: {
    addEventCategory() {
      this.$activities.post('/internalops/event/types', this.eventCategory).then(() => {
        this.successfulOperationAlert('Event type is added successfully')
        this.$bvModal.hide('add-modal')
        this.refreshTable()
      })
    },
    refreshTable() {
      this.$emit('refresh')
    },
  },
}
</script>
<style lang="scss">

</style>
